import React, { useEffect, useState } from 'react';
import { Card, Modal } from 'antd';
import { CarouselApp } from './carousel';
import CarouselPopupApp from './carouselForCard';
import { EditOutlined, StopOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getDevices, deleteDevices } from '../../toolkitReducers';

const { Meta } = Card;

export const CardElement = (props) => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const models = useSelector(({ state }) => state.models);

  useEffect(() => {}, [props.models]);

  const handleDelete = (value) => (event) => {
    event.stopPropagation(); // Предотвращаем всплытие клика на карточку
    dispatch(deleteDevices(value));

    setTimeout(() => {
      dispatch(getDevices());
    }, 500);
  };

  const handleEdit = (event) => {
    event.stopPropagation(); // Предотвращаем всплытие клика на карточку
    props.handleEditDevice(props.id)();
  };

  const getYearsArr = () => {
    if (Array.isArray(props.modelId)) {
      let values = props.yearsArray?.filter((item) =>
        props.yearId.includes(String(item.id))
      )
        .map((item) => item.years)
        .join(', ');

      return <>Роки: {values ? values : ""}</>;
    } else {
      return null;
    }
  };

  const getModelsArr = () => {
    if (Array.isArray(props.modelId)) {
      let values = props.models?.filter((moto) =>
        props.modelId.includes(String(moto.id))
      );
      let labels = values?.map((model) => model.model).join(', ');
      return <>Моделі: {labels ? labels : ""}</>;
    } else {
      return null;
    }
  };

  // Открытие модального окна
  const openModal = (e) => {
    // Проверяем, что клик не произошел на элементе точек навигации
    if (e.target.closest('.slick-dots, .slick-arrow')) {
      return; // Если это точка или стрелка навигации, не открываем модальное окно
    }
    setModalOpen(true);
  };

  // Закрытие модального окна
  const closeModal = (e) => {
    // Проверяем, что клик произошел на слайдере и не на точках или стрелках
    if (e.target.closest('.slick-dots, .slick-arrow')) {
      return; // Если клик на стрелке или точках навигации, не закрываем модальное окно
    }
    setModalOpen(false);
  };

  return (
    <>
      <Card
        hoverable
        onClick={openModal} // Открытие модального окна при клике на карточку
        actions={
          props.isLoggedIn
            ? [
                <EditOutlined
                  onClick={handleEdit} // Редактирование без открытия модального окна
                  key="edit"
                />,
                <StopOutlined
                  onClick={handleDelete(props.id)} // Удаление без открытия модального окна
                  key="delete"
                />,
              ]
            : false
        }
        className={"card-style"}
        cover={<CarouselApp images={props.images} />}
      >
        <Meta
          title={
            <>
              {props.autoTitle?.findType?.name} для <br />{" "}
              {props.autoTitle?.findMoto?.mark}
            </>
          }
          description={getModelsArr()}
        />
        <Meta title={props.name} description={getYearsArr()} />
        <br />
        <Meta title={props.name} description={props.title} />
        <h3>{props.price} USD</h3>
      </Card>

      {/* Модальное окно с увеличенным слайдером */}
      <Modal
        visible={isModalOpen}
        footer={null} // Убираем стандартные кнопки модального окна
        onCancel={closeModal} // Закрываем окно при клике на крестик
        maskClosable={true} // Закрываем при клике по маске
        onClick={closeModal} // Закрываем окно при клике на слайдер
        width={800} // Устанавливаем ширину модального окна
      >
        <div onClick={closeModal}>
          <CarouselPopupApp images={props.images} /> {/* Увеличенный слайдер */}
        </div>
      </Modal>
    </>
  );
};
