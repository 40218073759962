import React, { useState, useEffect } from 'react';
import { VerticalAlignBottomOutlined, CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const UploadElement = (props) => {
  const { setImageTab, isImageTab, imageUrl, setImageUrl, isimages, setimages } = props;

  const [inputKey, setInputKey] = useState(Date.now()); // Unique key for the file input

  useEffect(() => {
    // Очистка URL-ов, когда компонент размонтируется
    return () => {
      if (isimages && Array.isArray(isimages)) {
        isimages.forEach(image => {
          if (image.imageUrl) {
            URL.revokeObjectURL(image.imageUrl);
          }
        });
      }
    };
  }, [isimages]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newImages = Array.from(files).map(file => ({
        file,
        imageUrl: URL.createObjectURL(file)
      }));
      setimages(prevImages => prevImages ? [...prevImages, ...newImages] : newImages);
    }
  };

  const resetImg = (index) => {
    setimages(prevImages => prevImages.filter((_, i) => index !== i));
  };

  const handlePopupOpen = () => {
    setInputKey(Date.now()); // Update key to reset file input
  };

  return (
    <div>
      <input
        type="file"
        id="file-input-2"
        name="id-card"
        key={inputKey} // Use unique key to reset input state
        onChange={handleFileChange}
        multiple
      />

      <div className="file-preloader-nav">
        <label htmlFor="file-input-2" className="btn btn--secondary" onClick={handlePopupOpen}>
          <VerticalAlignBottomOutlined style={{ fontSize: "30px" }} />
        </label>
      </div>

      <div className="images-container">
        {isimages?.length ? (
          isimages.map((image, index) => (
            <span className="images-item-wrap" key={index}>
              <img
                src={image.imageUrl}
                alt="avatar"
              />
              <span onClick={() => resetImg(index)} className="btn btn--secondary">
                <CloseOutlined />
              </span>
            </span>
          ))
        ) : (
          <button
            style={{ border: 0, background: 'none' }}
            type="button"
          >
            <VerticalAlignBottomOutlined style={{ fontSize: "30px" }} />
          </button>
        )}
      </div>
    </div>
  );
};
