import { useState, useRef, useEffect } from 'react';
import { ModalLogin } from "../pages/main/modalLogin";
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { useSelector } from "react-redux";
import adminImg from './images.png';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

export default function Main(params) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const isAuth = useSelector(store => store.auth.isAuth);
    const navRef = useRef(null);

    const modalLoginClick = () => {
        setModalOpen(true);
    };

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    // Закрытие меню при клике по элементу
    const closeMenu = () => {
        setMenuOpen(false);
    };

    // Закрытие меню при клике вне меню
    const handleOutsideClick = (e) => {
        if (navRef.current && !navRef.current.contains(e.target)) {
            closeMenu();
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isMenuOpen]);

    return (
        <nav className="main-nav__wrapper" ref={navRef}>
            <ModalLogin isOpen={isModalOpen} setModalOpen={setModalOpen} />
            <div className="main-nav-logo" onClick={modalLoginClick}>
                <img src={logo} alt="Logo" />
                {isAuth && <div className='loged-in-icon'><img src={adminImg} alt="Admin" /></div>}
            </div>

            {/* Бургер-иконка */}
            <div className="burger-icon" onClick={toggleMenu}>
                {isMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
            </div>

            {/* Основное меню */}
            <ul className={`main-nav__first-lvl ${isMenuOpen ? 'open' : ''}`}>
                <li className="main-nav__first-lvl-item">
                    <NavLink className="link" to="main" onClick={closeMenu}>
                        <span>Головна</span>
                    </NavLink>
                </li>
                <li className="main-nav__first-lvl-item">
                    <NavLink className="link" to="detali" onClick={closeMenu}>
                        <span>Запчастини</span>
                    </NavLink>
                </li>
                <li className="main-nav__first-lvl-item">
                    <NavLink className="link" to="razborka" onClick={closeMenu}>
                        <span>Розборка</span>
                    </NavLink>
                </li>
                <li className="main-nav__first-lvl-item">
                    <NavLink className="link" to="about" onClick={closeMenu}>
                        <span>Про нас</span>
                    </NavLink>
                </li>
                <li className="main-nav__first-lvl-item">
                    <NavLink className="link" to="contacts" onClick={closeMenu}>
                        <span>Контакти</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
