
import razborka from "../../assets/images/razborka.jpeg";

export default function Main(params) {
  return (
    <div className="razborka">
      <h1>Про нас</h1>

    <div className="razborka-wrap">

      <div className="razborka-text">
        Наша компанiя займається продажем б/в та нових запчастин для мотоциклів
        з <b>1990 року</b>. На постійній основі ми скуповуємо биті,
        розукомплектовані або зламані мотоцикли та розбираємо їх на запчастини.
      </div>
      <div className="razborka-text">
        На даний момент з дати відкриття надіслано понад 12 000 замовлень!
      </div>
      <div className="razborka-text">
        Нам довіряють! <b> 67% наших клієнтів</b> зробили повторне замовлення
        або порекомендували нас своїм друзям!
      </div>
      <div className="razborka-text">
        <b>З 2014 року</b> ми намагаємося розбирати свіжіші мотоцикли об'ємом
        понад 600см³. Свіжа мототехніка - свіжі мотозапчастини.
      </div>
     
      <img className="razborka-img" src={razborka} alt="" />
      
      </div>

      <div className="razborka-text">
        Ми завжди раді <b>нашим клієнтам</b>, і далі продовжуватимемо намагатися
        бути кращими для Вас, розширювати асортимент б\у та нових мотозапчастин.
      </div>
      <div className="razborka-subtitle">
          Ми розуміємо і поділяємо ваші побоювання і для клієнтів з регіонів
          постараємося навести докази, які доводять нашу порядність:
      </div>
      <ul className="razborka-list">

        <li>На багатьох мотофорумах є наші теми про продаж мотозапчастин</li>
        <li>
          Як ще один доказ наявності у нас товару-на запит можемо зробити
          доп.фото товару
        </li>
        <li>
          Кожний товар на нашому сайті сфотографований нами особисто, що ще раз
          підтверджує наявність у нас товару.
        </li>
      </ul>
      <div className="razborka-subtitle">Нашi переваги: </div>
      <ul className="razborka-list">
        <li>
          Відправка Вашого оплаченого замовлення вже наступного робочого дня.
          Доставка запчастин можлива в будь-яку точку планети!
        </li>
        <li>Ми уклали договір про співпрацю з кур'єрською службою</li>
        <li>Дуже швидко обробляємо Ваші замовлення</li>
        <li>
          При разовому замовлення у сумі від 5000 грн. і вище - можлива знижка
        </li>
        <li></li>
      </ul>
    </div>
  );
}
