import React from 'react';
import { Carousel } from 'antd';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import  {ReactComponent as LeftOutlined} from './../../assets/icons/angle-left-icon.svg';
import  {ReactComponent as RightOutlined} from './../../assets/icons/angle-right-icon.svg';

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow left-arrow`}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <LeftOutlined />
        </div>
    );
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow right-arrow`}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <RightOutlined />
        </div>
    );
}

const CarouselApp = (props) => {
    return (
        <Carousel
            autoplaySpeed={3000}
            // autoplay
            arrows={true}
            prevArrow={<SamplePrevArrow />}
            nextArrow={<SampleNextArrow />}
        >
            {props.images?.map((image, i) => (
                <div className="carousel-img-wrapper" key={i}>
                    <img
                        src={process.env.REACT_APP_API_URL + image}
                        alt=""
                        className="carousel-img-popup"
                    />
                </div>
            ))}
        </Carousel>
    );
};

export default CarouselApp;