import Contacts from './Contacts'

export default function Main(params) {

    return (
        <>
            <>
        <div style={{ marginTop: '40px' }} className="container">
          {/* <Card
            title="Игорь и Валерия Харатин"
            img={cardImg}
            className="contacts-card"
            imgClass="contacts-img"
            text={Blabla()}
          /> */}
        </div>
      <div className="about contacts">
        <Contacts />
      </div>
      </>
        </>
    )
}